<template lang="pug">
#app
  v-container(grid-list-md, fluid)
    v-layout(v-if="$root.user.permissao != 0", row, wrap)
      AccessLock
    v-layout(v-if="$root.user.permissao == 0", row, wrap)
      v-flex(lg12)
        form(@submit.prevent="salvar")
          v-card.elevation-
            v-card-text.text-xs-right
              v-tabs(v-model="tab", slider-color="primary").elevation-0
                v-tabs-slider(color='primary')
                v-tab(:key="0").black--text.pa-2 Informações
                v-tab(:key="1", :disabled="!auditado.id").black--text.pa-2 Auditorias

                v-tab-item(:key="0").mt-3.pa-2
                  v-layout(row, wrap).pa-2
                    v-flex.md6.xs12
                      v-text-field(label="Auditado", color="gray", required, v-validate="'required'", :error-messages="errors.collect('nome')", data-vv-name="nome", data-vv-as="nome", type="text", v-model='auditado.nome')
                    v-flex.md6.xs12
                      v-autocomplete(label="Selecione o tipo de identificador", :items="listTiposIdentificadores", :disabled="!listTiposIdentificadores.length", item-text="nome", item-value="id", color="gray", v-model="auditado.tipo_identificador_id", :loading="loadingTiposIdentificadores", :clearable="true", autocomplete, :filter="customFilter", required, v-validate="'required'")
                  v-layout(row, wrap).pa-2
                    v-flex.md6.xs12
                      v-text-field(label="Identificador", color="gray", required, v-validate="'required'", :error-messages="errors.collect('identificador')", data-vv-name="identificador", data-vv-as="identificador", type="text", v-model='auditado.identificador')
                    v-flex.md6.xs12
                      v-text-field(label="Gestor", color="gray", :error-messages="errors.collect('nome_responsavel')", data-vv-name="nome_responsavel", data-vv-as="nome_responsavel", type="text", v-model='auditado.nome_responsavel')
                  v-layout(row, wrap).pa-2
                    v-flex.md6.xs12
                      v-text-field(label="Email do gestor", color="gray", :error-messages="errors.collect('email_responsavel')", data-vv-name="email_responsavel", data-vv-as="email_responsavel", type="text", v-model='auditado.email_responsavel')
                    v-flex.md6.xs12
                      v-text-field(label="Contato do gestor", color="gray", :error-messages="errors.collect('telefone_responsavel')", data-vv-name="telefone_responsavel", data-vv-as="telefone_responsavel", type="text", v-model='auditado.telefone_responsavel')
                  v-layout(row, wrap)
                    v-flex.xs6.pa-1
                      v-textarea(color="primary", label='Descrição', type="text", v-model='auditado.descricao')
                  v-layout(row, wrap)
                    v-flex.xs12.text-xs-right
                      v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar
                v-tab-item(:key="1")
                  v-card.elevation-0.pa-0
                    v-toolbar(card, color='white')
                      v-spacer
                      v-btn(color="bt-primary", :to="{ name: 'AuditadoAuditoriaCadastro', params: { id : idAuditado } }").white--text Cadastrar
                    v-divider
                    v-card-text.pa-0
                      v-data-table(:headers='headers', :search='filter.search', :items='auditado.auditorias', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]", :loading="loading")
                        template(slot='items', slot-scope='props')
                          td(width="5%").text-xs-center {{ props.item.id }}
                          td.text-xs-left.pa-1 {{ props.item.questionario.nome }}
                            p.overline {{ props.item.questionario.tipo_questionario.nome }}
                          td.text-xs-left {{ props.item.usuario.nome }}
                          td(width="10%").text-xs-center.text-uppercase {{ props.item.data_inicio | moment(' MMM [de] YYYY') }}
                          td(width="10%").text-xs-center
                            v-chip(label, small, :color="['#808080', '#EC971F', '#318837', '#C9302C'][props.item.status]").text-uppercase.white--text.font-weight-bold {{ props.item.status | statusAuditoria }}
                          td(width="15%").text-xs-center
                            v-tooltip(top)
                              v-btn(slot="activator", flat, icon, fab, dark, color='bt-primary', small, :to="{ name: 'AuditadoAuditoria', params: { id : idAuditado, idAuditoria : props.item.id } }")
                                v-icon edit
                              span Detalhes
                            v-tooltip(top)
                              v-btn(slot="activator", flat, icon, fab, dark, color='success', small, :to="{ name: 'ResultadosLista', params: { id: props.item.id } }")
                                v-icon visibility
                              span Resultados

</template>

<script>
import _ from "lodash";
import AccessLock from "@/components/AccessLock";

export default {
    components: {
        AccessLock,
    },
    $_veeValidate: {
        validator: "new",
    },
    data: () => ({
        filter: {
            search: "",
        },
        auditoriaAux: {},
        tab: 0,
        loading: false,
        loadingTiposIdentificadores: false,
        listTiposIdentificadores: [],
        auditado: {},
        dialogAuditoria: false,
        headers: [
            { text: "#", align: "center", value: "id" },
            { text: "Auditoria", align: "left", value: "questionario.nome" },
            {
                text: "Auditor Responsável",
                align: "left",
                value: "usuario.nome",
            },
            { text: "Data de Início", align: "center", value: "data_inicio" },
            { text: "Status", align: "center", value: "status" },
            { text: "Ações", align: "center", sortable: false },
        ],
    }),
    computed: {
        idAuditado() {
            const vm = this;
            return vm.$route.params.id;
        },
    },
    mounted() {
        const vm = this;
        if (vm.$route.params.id) {
            vm.getAuditado(vm.$route.params);
        }
        vm.getTiposIdentificadores();
    },
    methods: {
        async save(auditado) {
            const vm = this;
            try {
                const response = await vm.$axios.post("/auditado", auditado);
                auditado.id = response.data.id;
                vm.$router.push({
                    name: "AuditadoLista",
                    params: { id: response.data.id },
                });
                vm.loading = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Auditado salvo com sucesso!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao salvar o auditado. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async update(auditado) {
            const vm = this;
            try {
                const response = await vm.$axios.put(
                    `/auditado/${auditado.id}`,
                    auditado,
                );
                vm.loading = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Auditado salvo com sucesso!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao atualizar o auditado. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async salvar(scope) {
            const vm = this;
            try {
                vm.loading = true;
                const result = await vm.$validator.validateAll();
                if (!result) throw "Preencha todos os campos corretamente!";
                vm[vm.auditado.id ? "update" : "save"](vm.auditado);
                vm.$validator.reset();
            } catch (error) {
                window.getApp.$emit("APP_ALERT", { color: "red", text: error });
                vm.loading = false;
            }
        },
        async getAuditado(params) {
            let vm = this;
            try {
                const response = await vm.$axios.get(`/auditado/${params.id}`, {
                    params: { fields: "auditorias" },
                });
                vm.auditado = response.data || {};
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async getTiposIdentificadores() {
            const vm = this;
            try {
                vm.loading = true;
                const response = await vm.$axios.get("/tipo-identificador");
                vm.listTiposIdentificadores = response.data.rows;
                vm.loading = false;
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },

        async openDialogAuditoria(auditoria) {
            let vm = this;
            try {
                if (auditoria && auditoria.id) {
                    vm.auditoriaAux = _.cloneDeep(auditoria);
                    const response = await vm.$axios.get(
                        `/auditoria/${vm.auditoriaAux.id}`,
                    );
                    vm.auditoriaAux = response.data;
                }
                vm.dialogAuditoria = true;
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },

        customFilter(item, queryText, itemText) {
            const hasValue = (val) => (val != null ? val : "");
            const text = hasValue(item.nome);
            const query = hasValue(queryText);
            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            );
        },
    },
};
</script>

<style scoped></style>
